.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 1.1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;

  background-color: #f8f8f8;
  border: 1px solid #ececec;
  border-radius: 10rem;
  padding: 0.2rem 0.6rem;
  padding-left: 3rem;

  transition: all 0.4s ease;
}

.container:active {
  position: relative;
  transform: translateY(4px);
  transition: all 0s ease;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 50%;
  left: 0.8rem;
  transform: translateY(-50%);
  height: 1.563rem;
  width: 1.563rem;
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
  background-color: green;
} */

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0b64c2;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .container .checkmark:after {
  top: 0.5rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: white;
} */
