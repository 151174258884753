:root {
  font-size: 12px !important;
}

/* @media screen and (max-width: 1450px), screen and (max-height: 500px) {
  :root {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 900px), screen and (max-height: 500px) {
  :root {
    font-size: 10px !important;
  }
} */
