.App {
  font-family: var(--Poppins) !important;
  font-weight: 400;

  background-color: var(--background-color0);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

table, th, td  {
  border: 1px solid #cecece;
  border-collapse: collapse;
}

th {
  padding: 0.2rem 0.6rem;
}

td {
  padding: 0.2rem 0.6rem;
  text-align: center;
}

td input[type="checkbox"] {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

tbody input[type="number"] {
  width: 7rem;
}

img {
  display: block;
  object-fit: contain;
}

.swal-text,
.swal-title {
  font-family: sans-serif !important;
  font-size: 1.8rem !important;
  text-align: center !important;
}

.swal-title {
  font-size: 3rem !important;
}

input {
  background-color: #f8f8f8;
  border: 1px solid #ececec;
  color: var(--text-color0) !important;
  font-size: 1.2rem;
  border-radius: 10rem;
  padding: 0.2rem 0.6rem;
  font-family: var(--Poppins);
  width: 100%;
  transition: transform 0.4s ease;
}

input:active {
  transform: translateY(4px);
  transition: transform 0s ease;
}

input::placeholder {
  left: 0rem;
  transition: transform 500ms cubic-bezier(0.67, 0.01, 0.34, 0.99);
}

input:focus::placeholder {
  color: rgba(0, 0, 0, 0);
  left: -2rem;
  position: relative;
}

button:hover {
  filter: brightness(1.15);
  transition: transform 0.4s ease;
}

button:active {
  position: relative;
  transform: translateY(4px);
  transition: transform 0s ease;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

#root {
  --Poppins: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* start scrollbar */
::-webkit-scrollbar {
  width: 1rem;
  height: 1.125rem;
  background-color: white !important;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  height: 0.375rem;
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 0.438rem;
  border-radius: 0.5rem;
  background-color: rgb(185, 185, 185) !important;
  -webkit-box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
  box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
}

/* fonts */
@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
}


.btn_ {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-outline-danger_ {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}